
































.tbl_head {
  max-height: 32px;
  padding-bottom: 8px;
  font-size: 0;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.tbl_head .area_left:only-child .tit_tbl {
  padding-top: 0;
}
.tbl_head .tit_tbl {
  display: inline-block;
  padding-top: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #888;
  vertical-align: bottom;
}
.tbl_head .select_comm {
  width: 88px;
}
.tbl_head button + .select_comm,
.tbl_head button + .screen_out + .select_comm {
  margin-left: 8px;
}
.tbl_head .area_right .desc_tip {
  padding-top: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #555;
}
.tbl_head .area_right .tit_util {
  display: inline-block;
  padding-right: 12px;
  font-weight: normal;
  font-size: 12px;
  line-height: 31px;
  vertical-align: top;
}
.tbl_head .area_right button + button {
  margin-left: 8px;
}

.tbl_head .area_right .desc_alert.tc_red {
  display: inline-block;
  padding: 6px 16px;
  border: 1px solid #f8626c;
  font-size: 12px;
  line-height: 18px;
  background-color: #fef7f7;
  vertical-align: top;
}
.tbl_head .area_right .desc_alert.tc_red + button {
  margin-left: 8px;
}
